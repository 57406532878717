import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhonePipe} from './phone.pipe';
import {DateTimePipe} from './date-time.pipe';
import {NewLinePipe} from './new-line.pipe';

@NgModule({
  declarations: [
    PhonePipe,
    DateTimePipe,
    NewLinePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PhonePipe,
    DateTimePipe,
    NewLinePipe
  ],
})
export class PipesModule {
}
