// src/app/pipes/date-format.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, format: string = 'DD/MM/YYYY HH:mm'): string {
    if (!value) {
      return '';
    }
    console.log(format);
    console.log(moment(new Date(value)).format(format));
    return moment(value).format(format);
  }

}
