import { Injectable } from "@angular/core";
import { RegiaoSaude } from "../model/regiaoSaude.model";
import { RegiaoAdministrativa } from "../model/regiaoAdministrativa.model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SIGIH_API } from "../app.api";

@Injectable()
export class RegioesService {

    constructor(private http: HttpClient) { }

    getRegionsHealth(): Observable<RegiaoSaude[]> {
        return this.http.get<RegiaoSaude[]>(`${SIGIH_API}/regioes_saude`)
    }

    getRegionsAdministrativeByReagionHealthId(id: number): Observable<RegiaoAdministrativa[]> {
        return this.http.get<RegiaoAdministrativa[]>(`${SIGIH_API}/regioes_administrativas/regiao_saude/${id}`)
    }

    getRegionsAdministrative(): Observable<RegiaoAdministrativa[]> {
      return this.http.get<RegiaoAdministrativa[]>(`${SIGIH_API}/regioes_administrativas`)
  }

    getRegionsAdministrativeById(id: number): Observable<RegiaoAdministrativa> {
        return this.http.get<RegiaoAdministrativa>(`${SIGIH_API}/regioes_administrativas/${id}`)
    }

    getRegionsAdministrativesByNameAndReagionHealthId(nome: string, regiaoSaudeId: number): Observable<RegiaoAdministrativa[]> {
        return this.http.get<RegiaoAdministrativa[]>(`${SIGIH_API}/regioes_administrativas/nome_e_regiaosaudeid?nome=${nome}&id=${regiaoSaudeId}`)
    }

    getRegionHealthById(id: number): Observable<RegiaoSaude> {
        return this.http.get<RegiaoSaude>(`${SIGIH_API}/regioes_saude/${id}`)
    }

    saveRegionAdministrative(regiaoAdministrativa: RegiaoAdministrativa):Observable<RegiaoAdministrativa>{
        return this.http.post<RegiaoAdministrativa>(`${SIGIH_API}/regioes_administrativas`, regiaoAdministrativa);
    }

    editRegionAdministrative(regiaoAdministrativa: RegiaoAdministrativa):Observable<RegiaoAdministrativa>{
        return this.http.put<RegiaoAdministrativa>(`${SIGIH_API}/regioes_administrativas`, regiaoAdministrativa);
    }

    removeRegionAdministrative(id: number): Observable<any> {
        return this.http.delete<RegiaoAdministrativa>(`${SIGIH_API}/regioes_administrativas/${id}`)
    }
}
